@font-face {
  font-family: 'Mattilda';
  src: local('Mattilda'), url(./fonts/Mattilda.otf) format('opentype');
}

@font-face {
  font-family: 'Selectric';
  src: local('SLC_'), url(./fonts/SLC_.ttf) format('truetype');
}

@font-face {
  font-family: 'Crystal';
  src: local('CRYSRG__'), url(./fonts/CRYSRG__.TTF) format('truetype')
}

.main-app {
  font-family: 'Mattilda';
  width: 100vw;
  height: auto;
  background-color: black;
  color: white;
  text-align: center;
}


// ========================= //
// ======= DESKTOP NAV ===== //
// ========================= //


@media(min-width: 399px) {
  .mobile-header {
    display: none;
  }

  .header {
    margin-bottom: 40px;

    .logo {
      height: 300px;
      width: 300px;
    }

    .address {
      transform: translateY(-20px);
      font-size: 24px;
      font-family: sans-serif;
      margin-bottom: 24px;

      > div, a {
        font-family: 'Crystal';
      }

      .hours {
        font-size: 20px;
      }

      .header-link {
        color: white;
        text-decoration: none;

        &:hover {
          color: lightgray;
        }
      }
    }

    .nav-bar {
      color: white;
      font-size: 28px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      > div {
        width: 200px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}


// ========================= //
// ======= MOBILE NAV ====== //
// ========================= //


@media(max-width: 400px) {
  .header {
    display: none;
  }

  .mobile-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .mobile-logo-container {
      width: 100%;

      .logo {
        height: 125px;
        width: 125px;
      }
    }

    .address {
      // transform: translateY(-20px);
      font-size: 16px;
      font-family: sans-serif;
      margin-bottom: 16px;

      > a, div {
        font-family: 'Crystal';
      }

      .hours {
        font-size: 14px;
      }

      .header-link {
        color: white;
        text-decoration: none;
      }
    }

    .mobile-nav {
      position: absolute;
      color: white;
      text-align: center;

      /* Position and sizing of burger button */
      .bm-burger-button {
        position: fixed;
        width: 36px;
        height: 30px;
        right: 40px;
        top: 40px;
      }

      /* Position and sizing of clickable cross button */
      .bm-burger-bars {
        background: white;
      }

      /* Color/shape of close button cross */
      .bm-burger-bars-hover {
        background: white;
      }
      /*
        Sidebar wrapper styles
        Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
      */
      .bm-menu-wrap {
        position: fixed;
        height: 100%;
      }

      /* General sidebar styles */
      .bm-menu {
        margin-top: 65px;
        background: black;
        padding: 2.5em 1.5em 0;
        font-size: 1.75em;

        /* Individual item */
        .bm-item {
          margin-bottom: 10px;
          display: inline-block;
        }
      }

      /* Styling of overlay */
      .bm-overlay {
        background: rgba(0, 0, 0, 0.0)!important;
      }
    }
  }
}


// ========================= //
// ========== BODY ========= //
// ========================= //


.body {
  height: auto;
  width: 100vw;
  background-color: black;
  margin: 0 auto;
}


// ========================= //
// ========== HOME ========= //
// ========================= //

@media (min-width: 400px) {

  .home-container {
    height: 40vw;
    // height: 457.5px;
  }

  .home {
    width: 70%;
    height: 100vh;
  }

}

@media (max-width: 399px) {

  .home-container {
    height: 19vh;
  }

  .home {
    width: 70%;
    height: 100vh;
  }

}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('./public/subway-tile.jpg');

}

.home-bottom-spacer {
  height: 40vh;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  height: 80vh;

  .carousel {

    .carousel-picture {
      height: 40vw;
      borderTop: '1px solid white',

    }

    .legend {

    }
  }
}


// ========================= //
// ========= MENU ========== //
// ========================= //

@media (min-width: 400px) {
  .menu-title {
    width: 70%;
  }

  .announcement-line-one {
    font-size: 24px;
  }

  .announcement-line-two {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .specials-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .specials-content {
    border: 1px solid white;
    border-radius: 6px;
    margin: 0 auto;
    padding: 15px 0 15px 9%;
    width: 61%;
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .menu-items-container {
    width: 70%;

    .menu-section {

      .section-title {
        font-size: 36px;
      }

      .section-subtitle {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
  }

  .item-name-crystal {
    font-size: 25px;
  }
}

@media (max-width: 399px) {
  .menu-title {
    width: 90%;
  }

  .announcement-line-one {
    font-size: 16px;
  }

  .announcement-line-two {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .specials-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .specials-content {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .menu-items-container {
    width: 90%;

    .menu-section {

      .section-title {
        font-size: 36px;
      }

      .section-subtitle {
        font-size: 18px;
        margin-bottom: 24px;
      }
    }
  }

  .item-name-crystal {
    font-size: 20px;
  }
}

.menu {
  font-family: 'Crystal';
  height: auto;

  .menu-title {
    font-family: 'Mattilda';
    margin: 0 auto;
    font-size: 48px;
    margin-bottom: 16px;
    text-align: center;
    font-style: italic;
  }

  .menu-items-container {
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 6px;

    .menu-section {
      width: 75%;
      margin: 64px auto;

      .section-title {
        font-family: 'Mattilda';
        font-size: 36px;
        text-align: left;
      }

      .section-subtitle {
        text-align: left;
        // font-style: italic;
      }

      .section-item {
        margin: 12px 0;
        text-align: left;

        .item-name-mattilda {
          font-family: 'Mattilda';
          font-size: 25px;
        }

        .item-name-crystal {
          font-family: 'Crystal';
        }

        .section-subtitle {

        }

        .item-description {
          font-style: italic;
        }
      }

    }
  }
}


// ========================= //
// ========= ORDER ========= //
// ========================= //

@media (min-width: 399px) {
  .order {
    height: 70vh;

    .order-title {
      font-size: 36px;
      margin-bottom: 20px;
      margin-top: 72px;
    }

    .button-group {
      height: auto;
      width: 400px;
      margin: 0 auto;
      // border: 1px solid white;

      .order-button {
        height: 36px;
        line-height: 36px;
        font-size: 24px;
        width: 100px;
        margin: 0 auto;
        border: 1px solid white;
        border-radius: 3px;
        font-family: 'Crystal';

        &:hover {
          color: lightgray;
          border-color: lightgray;
          cursor: pointer;
        }

        &:active {
          transform: scale(.99);
        }
      }

      .catering-order-button {
        height: 36px;
        line-height: 36px;
        font-size: 24px;
        width: 200px;
        margin: 0 auto;
        border: 1px solid white;
        border-radius: 3px;
        font-family: 'Crystal';

        &:hover {
          color: lightgray;
          border-color: lightgray;
          cursor: pointer;
        }

        &:active {
          transform: scale(.99);
        }
      }

    }
  }
}

@media (max-width: 400px) {
  .order {
    height: 50vh;

    .order-title {
      font-size: 24px;
      margin-bottom: 14px;
      margin-top: 36px;
    }

    .button-group {
      height: auto;
      width: 300px;
      margin: 0 auto;
      // border: 1px solid white;

      .order-button {
        height: 36px;
        line-height: 36px;
        font-size: 20px;
        width: 88px;
        margin: 0 auto;
        border: 1px solid white;
        border-radius: 3px;
        font-family: 'Crystal';

        &:hover {
          color: lightgray;
          border-color: lightgray;
          cursor: pointer;
        }

        &:active {
          transform: scale(.99);
        }
      }

      .catering-order-button {
        height: 36px;
        line-height: 36px;
        font-size: 20px;
        width: 180px;
        margin: 0 auto;
        border: 1px solid white;
        border-radius: 3px;
        font-family: 'Crystal';

        &:hover {
          color: lightgray;
          border-color: lightgray;
          cursor: pointer;
        }

        &:active {
          transform: scale(.99);
        }
      }

    }
  }

}



// ========================= //
// ========= ABOUT ========= //
// ========================= //

@media (min-width: 399px) {
  .about {
    width: 80%;

    .about-title {
      font-size: 48px;
    }

    .about-content {
      font-size: 24px;

      .first-word {
        font-size: 28px;
        margin-left: 40px;
      }
    }
  }

}

@media (max-width: 400px) {
  .about {
    width: 90%;

    .about-title {
      font-size: 42px;
    }
  }

  .about-content {
    font-size: 18px;

    .first-word {
      font-size: 22px;
      margin-left: 20px;
    }
  }

}


.about {
  margin: 0 auto;
  min-height: 90vh;
  border: 1px solid white;
  border-radius: 6px;

  .about-title {
    margin: 48px 0 24px 0;
  }

  .about-content {
    font-family: 'Crystal';
    width: 80%;
    margin: 0 auto;
    text-align: left;
    color: white;

    .first-word {
      font-weight: bold;
    }

  }
}



// ========================= //
// ========= FOOTER ======== //
// ========================= //

.social {
  width: 100%;
  height: 100vh;

  .contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .contact-header {
      font-size: 36px;
      margin-bottom: 25px;
      // font-weight: bold;
    }

    .contact-phone {
      font-family: 'Crystal';
      font-size: 28px;
    }
  }

  .social-media {
    background-color: black;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-info-header {
    font-size: 36px;
    margin-bottom: 25px;
    // font-weight: bold;
  }

  @media(max-width: 699px){
    .social-info{
      flex-direction: column;
      font-size: 20px;
    }
  }

  @media(min-width: 700px){
    .social-info{
      flex-direction: row;
      font-size: 30px;
    }
  }

  .social-info{
    font-family: 'Crystal';
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    height: auto;
    padding-bottom: 10px;
    > div {
      // text-align: center;
    }
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media(max-width: 699px){
    .social-group{
      display: flex;
      flex-direction: row;
      align-items: left;
      justify-content: center;
    }
    .social-icons{
      transform: scale(.7);
      align-items: left;
    }
    .social-handle{
      text-align: left;
      display: flex;
      align-items: baseline;
      margin-top: 7.5px;
      width: 200px;
    }
  }

  @media(min-width: 700px){
    .social-icons{

    }
  }

  .social-icons {
    margin-right: 5px;
  }
}



// ========================= //
// ========= FOOTER ======== //
// ========================= //

.footer {
  width: 100%;
  height: 10vh;
  // border: 1px solid white;
  margin-top: 20px;
  line-height: 60px;
}
